<template>
    <div class="avatar" @click="toggle">
        {{ personalStore.person.first_name.charAt(0).toUpperCase() }}
    </div>

    <PrimePopover ref="op">
        <div class="popup-container">
            <div class="flex gap-3 items-center px-2 pb-2">
                <div class="avatar">
                    {{ personalStore.person.first_name.charAt(0).toUpperCase() }}
                </div>
                <div class="flex flex-col">
                    <span class="text-style-link capitalize">{{ personalStore.person.name }}</span>
                    <label>{{
                        personalStore.person.emails.length > 0
                            ? personalStore.person.emails[0].email
                            : `You haven't added an email`
                    }}</label>
                </div>
            </div>

            <div class="divider"></div>
            <div class="flex justify-between items-center">
                <div class="flex flex-col px-2 py-2">
                    <span class="text-style-link">Pivacy Bee Pro</span>
                    <label>$197/yr</label>
                </div>

                <div class="upgrade-btn" @click="useDynamicDialog('personal:upsell')">
                    <span>Upgrade</span>
                </div>
            </div>

            <div class="divider"></div>

            <div class="nav-item" @click="usePbRouter('/personal/manage-account', false)">
                <user-circle-icon class="stroke-slate-700" />
                <span class="text-style-link">Account Settings</span>
            </div>

            <div class="nav-item" @click="usePbRouter('/personal/support', false)">
                <support-icon class="stroke-slate-700" />
                <span class="text-style-link">Support</span>
            </div>

            <div class="divider"></div>

            <div class="nav-item">
                <logout-icon class="stroke-slate-700" />
                <span class="text-style-link">Sign out</span>
            </div>
        </div>
    </PrimePopover>
</template>

<script setup>
import UserCircleIcon from '@/assets/icons/user-circle.svg';
import SupportIcon from '@/assets/icons/support.svg';
import LogoutIcon from '@/assets/icons/logout.svg';

import PrimePopover from 'primevue/popover';
import PbButton from '@/components/shared/PbButton.vue';

const op = ref();

const toggle = (event) => {
    op.value.toggle(event);
};

const personalStore = usePersonalStore();
</script>

<style lang="scss" scoped>
.popup-container {
    @apply bg-white-700 p-4 rounded-lg relative top-7 border border-slate-300;

    animation: alternate 0.3s ease-in-out slideIn;
}

.avatar {
    @apply select-none w-8 h-8 rounded-[50%] bg-slate-100 flex items-center justify-center font-inter border-none text-slate-700 font-semibold text-sm cursor-pointer;
}

.divider {
    @apply bg-slate-200 my-2 h-px;
}

.nav-item {
    @apply p-2 flex items-center gap-3;

    &:hover {
        @apply bg-slate-100 cursor-pointer rounded;
    }
}

.upgrade-btn {
    @apply flex items-center justify-center gap-2 h-8 px-3 rounded-3xl cursor-pointer select-none relative z-0 overflow-hidden py-2 px-4;

    span {
        @apply font-inter font-semibold text-sm;
        
        background: linear-gradient(to right, #2563eb 20%, #d946ef 40%, #d946ef 60%, #2563eb 80%);
        background-size: 200% auto;
        
        color: #000;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        
        animation: shine 2s linear infinite;
        @keyframes shine {
            to {
                background-position: 200% center;
            }
        }
    }
	
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -100%;
		width: 200%;
		height: 400%;
		background-color: #2563eb;
		background-repeat: no-repeat;
		background-size: 100% 100%, 100% 100%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#2563eb, #d946ef), linear-gradient(#d946ef, #2563eb);
		animation: rotate 4s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 1px;
		top: 1px;
		width: calc(100% - 2px);
		height: calc(100% - 2px);
		background: #eff6ff;
		border-radius: 40px;
	}
}
</style>
