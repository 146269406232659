<template>
    <header class="app-header" :class="{ 'no-border': !hasBorder }">
        <div class="flex gap-4 md:gap-9 justify-center items-center">
            <mobile-navigation />
            <h1 class="page-name">{{ route.name }}</h1>
        </div>
        <div class="flex gap-4 items-center">
            <search-bar />
            <extension-popup />
            <protection-badge v-if="personalStore.person" />
            <user-popup v-if="personalStore.person" />
        </div>
    </header>
</template>

<script setup>
//comps
import UserPopup from '@/components/layouts/AppHeader/UserPopup.vue';
import ProtectionBadge from '@/components/layouts/AppHeader/ProtectionBadge.vue';
import ExtensionPopup from './AppHeader/ExtensionPopup.vue';
import SearchBar from '@/components/layouts/AppHeader/SearchBar.vue';
import MobileNavigation from '@/components/layouts/MobileNavigation.vue';

const route = useRoute();
const personalStore = usePersonalStore();

defineProps({
    hasBorder: {
        type: Boolean,
        default: true,
    },
});
</script>

<style lang="scss" scoped>
.app-header {
    @apply bg-white-700 h-[var(--size-header-height)] px-9 flex items-center justify-between;
    @apply border-b border-slate-300 shadow-sm fixed z-[1] w-full lg:w-[calc(100%-280px)];
}

.page-name {
    text-overflow: ellipsis;

    @apply text-lg md:text-2xl whitespace-nowrap overflow-hidden w-32 md:w-auto;
}

.no-border {
    @apply border-b-0 shadow-none;
}
</style>
